import React from "react";
// Customizable Area Start
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import {
  searchIcon,
  noPropertieisIcon,
  downloadIcon,
  coldWaterIcon,
  hotWaterIcon,
} from "./assets";
// Customizable Area End

import ReportsPageController, {
  ReportPaymentStatus,
  Props,
  configJSON,
} from "./ReportsPageController";
// Customizable Area Start
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const PAYMENT_STATUS_COLOR_MAP = {
  [ReportPaymentStatus.PAID]: { color: '#059669', backgroundColor: '#D1FAE5' },
  [ReportPaymentStatus.PENDING]: { color: '#D97706', backgroundColor: '#FEF3C7' },
  [ReportPaymentStatus.NO_INVOICE]: { color: '#475569', backgroundColor: '#F1F5F9' },
  [ReportPaymentStatus.PAST_DUE]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_30]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_60]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_90]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_120]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
}

const PAYMENT_STATUS_TEXT_MAP = {
  [ReportPaymentStatus.PAID]: 'Paid',
  [ReportPaymentStatus.PENDING]: 'Pending',
  [ReportPaymentStatus.NO_INVOICE]: 'No invoice',
  [ReportPaymentStatus.PAST_DUE]: 'Past due',
  [ReportPaymentStatus.PAST_DUE_30]: '30+ days past due',
  [ReportPaymentStatus.PAST_DUE_60]: '60+ days past due',
  [ReportPaymentStatus.PAST_DUE_90]: '90+ days past due',
  [ReportPaymentStatus.PAST_DUE_120]: '120+ days past due',
}

// Customizable Area End
export default class ReportsPage extends ReportsPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  renderTypeWithIcon(meterType: 'Water' | 'Electric', type: "Cold" | "Hot" | null) {
    if (meterType === 'Water' && type === "Cold") {
      return (
        <>
          Water &nbsp;
          <div style={{ display: "flex", alignItems: "center" }}>
            (
            <img
              src={coldWaterIcon}
              alt="Hot Icon"
              style={{ width: "16px", height: "16px" }}
            />{" "}
            &nbsp;Cold)
          </div>
        </>
      );
    } else if (meterType === 'Water' && type === "Hot") {
      return (
        <>
          Water &nbsp;
          <div style={{ display: "flex", alignItems: "center" }}>
            (
            <img
              src={hotWaterIcon}
              alt="Hot Icon"
              style={{ width: "16px", height: "16px" }}
            />{" "}
            &nbsp;Hot)
          </div>
        </>
      );
    } else {
      return <Typography sx={{fontSize:'14px',fontFamily:"'Inter', sans-serif"}}>{meterType}</Typography>;
    }
  }
  // Customizable Area End
  render() {

    if (this.state.reportsListLoading) {
        return (
          <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
            <CircularProgress sx={webStyle.loader} />
          </Box>
        );
      }


    return (
      // Customizable Area Start
      <Box sx={webStyle.reportsContainer}>
        {this.state.reportList.length === 0 ? (
          <Box sx={webStyle.noReportBlock}>
            <Box sx={webStyle.noReportBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography sx={webStyle.noReportTitle}>
                {configJSON.noReportTitleText}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={webStyle.reportsBlock}>
            <Box sx={webStyle.reportsTableHeader}>
              <Typography sx={webStyle.reportTitle}>
                {configJSON.reportText}
              </Typography>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchReports(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
              </Box>
              <Typography sx={webStyle.reportCount}>
                {this.state.filteredReportList.length + " results"}
              </Typography>
            </Box>
            {this.state.filteredReportList.length > 0 && (
                <Box sx={webStyle.tableWrapper(1)}>
              <Box sx={webStyle.reportsTable}>
                <Box sx={webStyle.reportsTableHead}>
                  <Typography sx={webStyle.headerTitle}>
                    {configJSON.reportTitleText}
                  </Typography>
                  <Typography sx={webStyle.headerTitle}>
                    {configJSON.propertyTitleText}
                  </Typography>
                  <Typography sx={webStyle.headerTitle}>
                    {configJSON.generatedAtText}
                  </Typography>
                  <Typography sx={webStyle.headerTitle}>
                    {configJSON.typeText}
                  </Typography>
                  <Typography sx={webStyle.headerTitle}>
                    {configJSON.paymentStatusText}
                  </Typography>
                  <Typography sx={webStyle.headerTitleAction}>
                    {configJSON.actionsText}
                  </Typography>
                </Box>
                {this.state.currentReports.map((report) => (
                  <Box sx={webStyle.reportsTableBody} key={report.report_id}>
                    <Box sx={webStyle.reportItem}>{report.report_id}</Box>
                    <Box sx={webStyle.reportItem}>{report.property_name}</Box>
                    <Box sx={webStyle.reportItem}>{report.generated_at}</Box>
                    <Box sx={webStyle.reportItem}>{report.meter_type}</Box>
                    <Box sx={webStyle.reportItem}>
                      <Typography sx={webStyle.selectOptionText(PAYMENT_STATUS_COLOR_MAP[report.payment_status]?.color,PAYMENT_STATUS_COLOR_MAP[report.payment_status]?.backgroundColor)}>
                        {PAYMENT_STATUS_TEXT_MAP[report.payment_status]}
                      </Typography>
                    </Box>
                    <Box sx={webStyle.reportItemAction}>
                      <Button
                        sx={webStyle.downloadButton}
                        onClick={() => {
                          this.getPdfUrl(report.report_id);
                        }}
                        data-test-id={"downloadReport"+report.report_id}
                        disabled={this.state.pdfReportId === report.report_id}
                      >
                        {
                          this.state.pdfReportId === report.report_id ? <CircularProgress size={24} sx={webStyle.loader} /> : 
                          <>
                            <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                            <Typography sx={webStyle.typographyText}>
                              {configJSON.reportButtonText}
                            </Typography>
                          </>
                        }
                      </Button>
                      <Button
                        sx={{ ...webStyle.downloadButton, width: "95px" }}
                        onClick={() => {
                          this.getInvoicePdfUrl(report.report_id);
                        }}
                        data-test-id={"downloadInvoice"+report.report_id}
                        disabled={this.state.pdfInvoiceId === report.report_id}
                      >
                        {
                          this.state.pdfInvoiceId === report.report_id ? <CircularProgress size={24} sx={webStyle.loader} /> : 
                          <>
                            <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                            <Typography sx={webStyle.typographyText}>
                              {configJSON.invoiceButtonText}
                            </Typography>
                          </>
                        }
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
              
            </Box>
              )}
            {this.state.totalPages > 1 &&
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={() => this.handlePrevPage()}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={this.state.currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon sx={this.state.currentPage === 1 ? webStyle.chevronIconDisabled : webStyle.chevronIconActive} />
                  </button>

                  {this.state.totalPages <= 3 ? (
                    [...Array(this.state.totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width: this.state.currentPage === page + 1 ? '24px' : '16px',
                          height: '24px',
                          backgroundColor:
                            this.state.currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: '12px',
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.currentPage !== page + 1
                                ? 400
                                : 700,
                        }}
                        onClick={() => this.handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {this.state.currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: '16px',
                            height: '24px',
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() => this.handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {this.state.currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: '#64748B' }}>...</span>
                      )}

                      {this.state.currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: '24px',
                            height: '24px',
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage - 1)
                          }
                        >
                          {this.state.currentPage - 1}
                        </button>
                      )}

                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: '24px',
                          height: '24px',
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: '12px',
                          fontFamily: "'Inter', sans-serif"
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage)
                        }
                      >
                        {this.state.currentPage}
                      </button>

                      {this.state.currentPage < this.state.totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: '16px',
                            height: '24px',
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage + 1)
                          }
                        >
                          {this.state.currentPage + 1}
                        </button>
                      )}

                      {this.state.currentPage < this.state.totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: '#64748B' }}>...</span>
                      )}

                      {this.state.currentPage !== this.state.totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width: this.state.currentPage === this.state.totalPages ? '24px' : '16px',
                            height: '24px',
                            backgroundColor:
                              this.state.currentPage === this.state.totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              this.state.currentPage === this.state.totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              this.state.currentPage !== this.state.totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              this.state.currentPage !== this.state.totalPages
                                  ? 400
                                  : 700,
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.totalPages)
                          }
                        >
                          {this.state.totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => this.handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={this.state.currentPage === this.state.totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon sx={this.state.currentPage === this.state.totalPages ? webStyle.chevronIconDisabled : webStyle.chevronIconActive} />
                  </button>
                </Box>
  
                <Box sx={{ marginRight: "10px", width: "120px"}}>
                  <Typography
                    fontFamily={"'Inter', sans-serif"}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                    {this.state.filteredReportList.length} results
                  </Typography>
                </Box>
                </Box>
              }
          </Box>
        )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(248, 250, 252)",
    padding: "0px 30px 40px",
    boxSizing: "border-box",
    minHeight: "100%",
    "@media (max-width: 500px)": {
      padding: "0px 10px",
    }
  },
  reportsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "40px 0px 24px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
    "@media (max-width: 500px)": {
      width: "100%",
    }
  },
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    minWidth: "870px",
    // "@media (max-width: 1330px)": {
      // "@media (max-width: 767px)": {
    // },
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? '535px' : 'unset',
    marginBottom: "30px",
    "@media (max-width: 1330px)": {
    // "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: '24px 0',
    position: "relative",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(20% - 50px)",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 1330px)": {
      fontSize: "10px",
      // width: "150px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "12px",
      // width: "100%",
    },
    "@media (max-width: 870px)": {
      fontSize: "10px",
      // width: "100%",
    },
    "@media (max-width: 500px)": {
      // width: "100%",
    },
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 1330px)": {
      fontSize: "10px",
      // width: "150px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "12px",
      // width: "100%",
    },
    "@media (max-width: 870px)": {
      fontSize: "10px",
      // width: "100%",
    },
    "@media (max-width: 500px)": {
      // width: "100%",
    },
  },
  downloadButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    width: "92px",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(20% - 50px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
      // width: "150px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "14px",
      // width: "100%",
    },
    "@media (max-width: 870px)": {
      fontSize: "12px",
      // width: "100%",
    },
    "@media (max-width: 500px)": {
      // width: "100%",
    },
  },
  selectOptionText: (color: string, backgroundColor: string) => ({
    textTransform: "uppercase" as const,
    backgroundColor,
    color,
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  }),
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
      // width: "150px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "14px",
      // width: "100%",
    },
    "@media (max-width: 870px)": {
      fontSize: "12px",
      // width: "100%",
    },
    "@media (max-width: 500px)": {
      // width: "100%",
    },
  },
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  reportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
  },
  reportCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },
   chevronIconActive: {
    '> path': {
      fill: '#475569',
    }
  },
  chevronIconDisabled: {
    '> path': {
      fill: '#64748B',
    }
  },
};
// Customizable Area End
