import React, { Key, ReactNode, ReactPortal } from "react";
// Customizable Area Start

import { Typography, Box, Button, Tooltip, Snackbar } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

const errorIcon = require("../assets/errorIcon.svg");
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { createPortal } from 'react-dom';
// Customizable Area End

import SupportPageController, {
  Props,
  configJSON,
} from "./SupportPageController";
// Customizable Area Start

// Customizable Area End
export default class SupportPage extends SupportPageController {
  constructor(props: Props) {
    super(props);
  }

  supportSchema = () => {
    return Yup.object().shape({
      servicesNeeded: Yup.string().trim().required("Services Need is required"),
      message: Yup.string().trim().required("Message is required"),
    });
  };

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.supportContainer}>
        <Box sx={webStyle.supportBlock}>
          <Box sx={webStyle.supportFormContainer}>
            <Box sx={webStyle.supportTitle}>
              <Typography style={webStyle.formTitle}>
                {configJSON.supportTitleText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                servicesNeeded: "",
                message: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.supportSchema}
              onSubmit={(_,{resetForm}) => {
                this.setValidate(true);
                this.sendSupportRequestHandler(resetForm);
              }}
              data-test-id="supportForm"
            >
              {({ setFieldValue, values, errors, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.servicesNeededText}
                      </Typography>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="servicesNeeded"
                        type="text"
                        value={values?.servicesNeeded}
                        onChange={(event) => {
                          setFieldValue("servicesNeeded", event.target.value);
                        }}
                      />
                       {this.state.validate &&
                        errors?.servicesNeeded && (
                          <Tooltip
                            open={Boolean(
                              errors?.servicesNeeded
                            )}
                            title={errors?.servicesNeeded}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.messageText}
                      </Typography>
                      <textarea
                        style={webStyle.textAreaElement}
                        data-test-id="message"
                        value={values?.message}
                        onChange={(event) => {
                          setFieldValue("message", event.target.value);
                        }}
                      />
                      {this.state.validate &&
                        errors?.message && (
                          <Tooltip
                            open={Boolean(
                              errors?.message
                            )}
                            title={errors?.message}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                    </Box>
                    <Box sx={webStyle.fieldControlFirstStepGroup}>
                      <Button
                        sx={webStyle.submitButton}
                        data-test-id="submitBtn"
                        // type="button"
                        onClick={() => {
                          console.log(values, errors, "values");
                          this.setState({
                            services_needed: values?.servicesNeeded,
                            message: values?.message
                          });
                          if (Object.keys(errors).length === 0) {
                            handleSubmit();
                          }
                        }}
                        disabled={this.state.sending}
                      >
                        <Typography sx={webStyle.buttonText}>
                          {configJSON.sendText}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        {
          createPortal(
            <Snackbar
              open={this.state.sendResultMsg !== null}
              message={(
                <Box sx={webStyle.snackbarContent}>
                  {
                    this.state.sendResultMsg === "Request Sent Failed" ? (
                      <CancelRoundedIcon sx={webStyle.snackbarIcon} />
                    ) : (
                      <CheckCircleRoundedIcon
                        sx={{
                          ...webStyle.snackbarIcon,
                          ...webStyle.snackbarSuccessIcon,
                        }}
                      />
                    )
                  }
                  <Typography sx={webStyle.snackbarText}>
                    {this.state.sendResultMsg}
                  </Typography>
                </Box>
              )}
              autoHideDuration={3500}
              onClose={() => this.setState({ sendResultMsg: null })}
              sx={webStyle.snackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />,
            document.body
          )
        }
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};
const webStyle = {
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  supportContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    height: '100%',
  },
  supportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  supportFormContainer: {
    margin: "40px auto 0",
    maxWidth: "600px",
    width: "80%",
  },
  supportTitle: {
    margin: "0 10px 40px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: "15px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    // "@media (max-width: 1330px)": {
    //   fontSize: "12px",
    // },
    // "@media (max-width: 1036px)": {
    //   fontSize: "14px",
    // },
    "@media (max-width: 870px)": {
      // width: "50%",
      fontSize: "12px",
    },
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "10px 16px",
    flexDirection: "row" as const,
    display: "flex",
    textTransform: "none",
    alignItems: "center",
    "@media (max-width: 500px)": {
      // width: "50%",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
    "&:disabled": {
      backgroundColor: "#1A7BA466",
      color: "#FFFFFF",
    },
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    // "@media (max-width: 1330px)": {
    //   fontSize: "14px",
    // },
    // "@media (max-width: 1036px)": {
    //   fontSize: "16px",
    // },
    "@media (max-width: 870px)": {
      // width: "50%",
      fontSize: "14px",
    },
  },
  snackbar: {
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: '#FFFFFF',
      fontFamily: "'Inter', sans-serif !important",
      padding: '8px 16px 8px 8px',
      boxShadow: '0px 6px 15px -3px #00000026',
      borderRadius: '8px',
      minWidth: 'auto',
      "& .MuiSnackbarContent-message": {
        padding: 0,
      }
    },
    "@media (min-width: 1037px)": {
      left: '50% !important',
      transform: 'translateX(-50%)',
    },
  },
  snackbarContent: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  snackbarIcon: {
    width: '26px',
    height: '26px',
    '> path': {
      fill: '#F87171',
    }
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  }
};
// Customizable Area End
